<template>
  <div class="flex flex-columns text-center justify-space-between fill-height py-6">
    <v-row class="text-center justify-space-between rr flex-grow-0">
      <v-col cols="12">
        <h1>{{ $t('quickStart.title') }}</h1>
      </v-col>
    </v-row>
    <v-row
      cols="12"
      class="text-center flex-grow-0 justify-space-around"
    >
      <v-col
        v-for="item in navigationElements"
        v-show="item.available"
        :key="item.title"
        class="card-container"
        sm="6"
        lg="3"
        cols="12"
      >
        <custom-card :creation-data="item">
          {{ item.title }}
        </custom-card>
      </v-col>
    </v-row>
    <v-row
      cols="12"
      class="flex-grow-0"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
const CustomCard = () => import('@/components/GlobalComponents/CustomCard')

export default {
  name: 'QuickStartMachine',
  components: {
    CustomCard
  },
  computed: {
    ...mapGetters('User', ['getUserData']),
    navigationElements () {
      return [
        {
          available: this.getUserData.role === 'OPERATOR' || this.getUserData.role === 'ADMIN' || this.getUserData.role === 'HEAD_MAINTAINER',
          routeName: 'ErrorReportMachine',
          icon: 'mdi-alert-octagon',
          title: this.$t('quickStart.reportError.title')
        },
        {
          available: this.getUserData.role === 'MAINTAINER' || this.getUserData.role === 'ADMIN' || this.getUserData.role === 'HEAD_MAINTAINER',
          routeName: 'CurrentMachineErrors',
          icon: 'mdi-bulletin-board',
          title: this.$t('quickStart.dashboardError.title')
        },
        {
          available: this.getUserData.role === 'MAINTAINER' || this.getUserData.role === 'ADMIN' || this.getUserData.role === 'HEAD_MAINTAINER',
          routeName: 'TaskDashboardMachine',
          icon: 'mdi-briefcase',
          title: this.$t('quickStart.taskDashboard.title')
        },
        {
          available: this.getUserData.role === 'ADMIN' || this.getUserData.role === 'HEAD_MAINTAINER',
          routeName: 'ErrorAssignMachine',
          icon: 'mdi-account-hard-hat',
          title: this.$t('quickStart.errorAssign.title')
        },
        {
          available: this.getUserData.role === 'ADMIN' || this.getUserData.role === 'HEAD_MAINTAINER',
          routeName: 'PerformancePageMachine',
          icon: 'mdi-speedometer',
          title: this.$t('quickStart.kpiPage.title')
        }
      ]
    }
  }
}
</script>
<style scoped>
  .card-container {
    position: relative;
    display: flex;
    align-items: stretch;
  }
  .flex {
    display: flex;
    flex-direction: column;
  }
</style>
